<template>
  <div class="tw-max-w-9xl tw-mx-auto sm:tw-px-8 lg:tw-px-16 tw-px-3">

      <div v-if="status" class="tw-pt-24 tw-text-center">
        <v-alert outlined prominent border="left"  type="success">
          <div class="title">{{ this.status }}</div>
        </v-alert>
      </div>

    <div class="tw-pt-24 tw-text-center">
      <h1 class="tw-block tw-text-black tw-text-xl tw-leading-relaxed tw-font-semibold tw-mb-2">{{ $t('auth.reset_password') }} </h1>
      <p class="tw-text-sm tw-text-gray-n3 tw-mb-0">Please enter the email address you'd like your password reset information sent to</p>
    </div>
    <form class="tw-w-2/5 tw-mx-auto tw-text-left tw-mt-6" @submit.prevent="send" method="POST">
      <div>
        <label for="email" class="tw-mb-0 tw-block tw-text-sm tw-font-semibold tw-text-gray-n3 tw-leading-relaxed">
          {{ $t('auth.email') }}<span class="tw-text-red">*</span>
        </label>
        <div class="tw-mt-2">
          <input v-model="form.email" autofocus id="email" name="email" type="email" autocomplete="email" required class="tw-appearance-none tw-block tw-w-full tw-px-4 tw-py-3 tw-border tw-border-solid tw-border-gray-n1 tw-rounded-xl tw-placeholder-gray-n1 focus:tw-outline-none focus:tw-ring-black focus:border-black sm:tw-text-sm">
        </div>
      </div>

      <button type="submit" class="tw-mt-6 tw-flex tw-justify-center tw-w-full tw-px-6 tw-py-4 tw-border tw-border-transparent tw-text-base tw-font-medium tw-rounded-xl tw-shadow-sm tw-text-white tw-bg-gray-n4 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-gray-n4">
        {{ $t('auth.request_password_reset_link') }}
      </button>
    </form>

  </div>

</template>

<script>
import Form from 'vform'
import i18n from '@/i18n'

export default {
  middleware: 'guest',

  metaInfo () {
    return { title: i18n.t('auth.reset_password') }
  },

  data: () => ({
    status: '',
    valid: false,
    form: new Form({
      email: ''
    }),
    emailRules: [
          v => !!v || i18n.t('auth.email_required'),
          v => /.+@.+/.test(v) || i18n.t('auth.email_valid'),
    ],
  }),
  methods: {
    async send () {
      await this.$http.get('sanctum/csrf-cookie')
      const { data } = await this.form.post('/api/password/email')

      this.status = data.status

      this.form.reset()
    }
  }
}
</script>
